/**
 * api-mixin.js
 * requires:
 * provides:
 *    data: [isLoading]
 *    methods: [apiGet]
 */
// import apiMixin from '@/mixins/api-mixin.js'

import { apiHost } from '@/config'
import axios from 'axios'

export default {
  data: () => ({
    isLoading: false,
  }),
  methods: {
    apiGet(endpoint, callback) {
      this.isLoading = true
      let that = this
      axios
        .get(apiHost + endpoint)
        .then(response => {
          let responseData = response.data
          callback(responseData)
          this.isLoading = false
        })
        .catch(function(error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          that.isLoading = false
        })
    },
  },
}
