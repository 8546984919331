<template>
  <v-row
    :id="'rowlo-poem-verse-' + titleId"
    align="center"
    justify="center"
    align-content="center"
    class="ma-0 pa-0 pb-2"
  >
    <v-col
      v-if="needsToPrependVerseImage"
      :cols="hasImageOnLeft ? 3 : 12"
      class="ma-0 pa-0"
      :class="{ 'pr-2': hasImageOnLeft }"
    >
      <v-row
        align="center"
        :justify="hasImageOnLeft ? 'end' : 'center'"
        class="ma-0 pa-0"
      >
        <v-img
          :src="verseImageSrc"
          position="center"
          :aspect-ratio="verseImageAspectRatio"
          :height="verseImageHeight"
          :max-height="verseImageHeight"
          :width="verseImageWidth"
          :max-width="verseImageWidth"
        ></v-img>
      </v-row>
    </v-col>
    <v-col :cols="hasImageOnLeftOrRight ? 9 : 12" class="ma-0 pa-0">
      <v-row
        align="center"
        :justify="hasImageAboveOrBelow ? 'center' : 'start'"
        class="ma-0 pa-0"
      >
        <p class="ma-0 pa-0">
          <span
            v-for="(line, l) in verse.lines"
            :key="l"
            :class="{ 'pl-6': line.startsWith('\t') }"
            >{{ line }}<br
          /></span>
        </p>
      </v-row>
    </v-col>
    <v-col
      v-if="needsToAppendVerseImage"
      :cols="hasImageOnRight ? 3 : 12"
      class="ma-0 pa-0"
      :class="{ 'pl-2': hasImageOnRight }"
    >
      <v-row
        align="center"
        :justify="hasImageOnRight ? 'start' : 'center'"
        class="ma-0 pa-0"
      >
        <v-img
          :src="verseImageSrc"
          position="center"
          :aspect-ratio="verseImageAspectRatio"
          :height="verseImageHeight"
          :max-height="verseImageHeight"
          :width="verseImageWidth"
          :max-width="verseImageWidth"
        ></v-img>
      </v-row>
    </v-col>
    <v-col v-if="needsVerticalSpacer" class="ma-0 pa-0">
      <div class="vertical-spacer"></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'rowlo-poem-card',
  props: {
    titleId: {
      type: String,
      required: true,
    },

    verse: Object,
  },
  computed: {
    hasVerseImage() {
      return this.verse && this.verse.image && this.verse.image.src
    },
    hasVerseImagePosition() {
      return this.hasVerseImage && this.verse.image.position
    },
    needsVerticalSpacer() {
      return (
        this.hasVerseImagePosition &&
        ['above', 'below'].includes(this.verse.image.position)
      )
    },
    needsToPrependVerseImage() {
      return (
        this.hasVerseImagePosition &&
        ['left', 'above'].includes(this.verse.image.position)
      )
    },
    needsToAppendVerseImage() {
      return (
        this.hasVerseImagePosition &&
        ['right', 'below'].includes(this.verse.image.position)
      )
    },
    hasImageOnLeft() {
      return this.hasVerseImagePosition && 'left' === this.verse.image.position
    },
    hasImageOnRight() {
      return this.hasVerseImagePosition && 'right' === this.verse.image.position
    },
    hasImageOnLeftOrRight() {
      return (
        this.hasVerseImagePosition &&
        ['left', 'right'].includes(this.verse.image.position)
      )
    },
    hasImageAboveOrBelow() {
      return (
        this.hasVerseImagePosition &&
        ['above', 'below'].includes(this.verse.image.position)
      )
    },
    verseImageSrc() {
      return this.hasVerseImage ? '/images/' + this.verse.image.src : null
    },
    verseImageAspectRatio() {
      return this.hasVerseImage ? this.verse.image.aspectRatio : 1.0
    },
    verseImageHeight() {
      return this.hasVerseImage ? this.verse.image.height : 1
    },
    verseImageWidth() {
      return this.hasVerseImage ? this.verse.image.width : 1
    },
  },
}
</script>
