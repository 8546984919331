<template>
  <div id="rowlo-poem">
    <v-row v-if="poem" class="ma-0 pa-0">
      <rowlo-title
        :title="computeTitle + ': ' + $breakpoint.name"
      ></rowlo-title>

      <rowlo-circular-progress
        v-if="isLoading || !poem.verses"
      ></rowlo-circular-progress>
      <v-col
        v-else
        xl="2"
        offset-xl="6"
        lg="4"
        offset-lg="4"
        sm="8"
        offset-sm="2"
        cols="12"
        offset="0"
        class="pa-0"
      >
        <rowlo-poem-card
          :poem="poem"
          :startWithFullText="true"
          :withSignature="true"
          :withToPoemsRoute="true"
        ></rowlo-poem-card>
      </v-col>
    </v-row>
    <v-alert v-else type="error" :value="true">{{ $t('unknownPoem') }}</v-alert>
  </div>
</template>

<script>
import { eventBus } from '@/main.js'
import breakpoint from 'vue-md-breakpoint'
import apiMixin from '@/mixins/api-mixin.js'
import RowloPoemCard from '@/components/rowlo-poem-card'
import RowloCircularProgress from '@/components/rowlo-circular-progress'

export default {
  name: 'rowlo-poem',
  mixins: [breakpoint, apiMixin],
  components: {
    'rowlo-poem-card': RowloPoemCard,
    'rowlo-circular-progress': RowloCircularProgress,
  },
  data: () => ({
    poem: null,
    timer: '',
  }),
  computed: {
    computeTitle() {
      let title = this.poem ? ' - ' + this.poem.title : ''
      return this.$t('siteTitle') + this.$t('siteSubtitleGoWrite') + title
    },
    cyclePoems() {
      return eventBus.cycleInRoute
    },
  },
  methods: {
    processPoemsSummary(poemsSummary) {
      eventBus.clearPoems()
      eventBus.awaitPoems(poemsSummary.length)
      poemsSummary.forEach(x => {
        eventBus.addPoemSummary(x)
      })
      this.fetchPoem()
    },
    fetchData() {
      this.apiGet('poems/poemssummary', this.processPoemsSummary)
    },
    processPoem(poem) {
      eventBus.updatePoem(poem)
    },
    fetchPoem() {
      this.apiGet('poems/poem/' + this.$route.params.poemId, this.processPoem)
    },
    acquirePoem() {
      if (eventBus === undefined) return
      let poems = eventBus.getPoems()
      if (poems === undefined || poems.length === 0) return
      this.poem = poems.find(
        p => !p.image && p.id.toString() === this.$route.params.poemId
      )
      if (this.poem === undefined) this.poem = null
    },
    changePoemId() {
      if (eventBus === undefined) return
      let poems = eventBus.getPoems()
      if (poems === undefined || poems.length === 0) return
      this.$route.params.poemId = Math.floor(
        Math.random() * poems.length + 1
      ).toString()
      this.fetchPoem()
    },
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },
  },
  mounted() {
    eventBus.updateI18nFromRoute(this.$route.query)
    eventBus.$on('poemWasAdded', () => {
      this.acquirePoem()
    })
    this.acquirePoem()
    if (this.cyclePoems) {
      this.timer = setInterval(this.changePoemId, 180000)
    }
  },
  created() {
    if (eventBus === undefined) return
    let poems = eventBus.getPoems()
    if (!poems || poems.length === 0) {
      this.fetchData()
    } else {
      this.fetchPoem()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.acquirePoem()
    },
  },
}
</script>
