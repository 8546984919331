export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    lineHeight: {
      type: Number | String,
      required: false,
      default: '20',
    },
    lines: {
      type: Number | String,
      required: false,
      default: '3',
    },
    maxWidth: {
      type: Number | String,
      required: false,
      default: '100%',
    },
    top: {
      type: Boolean,
      required: false,
      default: true,
    },
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    openDelay: {
      type: Number | String,
      required: false,
      default: '500',
    },
  },
}
