var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":'rowlo-poem-card-' + _vm.poem.title.replace(/\s/g, '-').toLowerCase(),"color":_vm.poem.color}},[_c('v-card-title',{class:['title', 'white-title', { clickable: _vm.canToggleShowFullText }],on:{"click":_vm.toggleShowFullText}},[(!_vm.poem.image)?_c('tooltipped-span',{attrs:{"id":'poem-' + _vm.poem.id,"text":_vm.$tc('views', _vm.poem.views, { n: _vm.poem.views })}},[_vm._v("#"+_vm._s(_vm.poem.id.toString().padStart(3, '0'))+": "+_vm._s(_vm.poem.title)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t(_vm.poem.title)))])],1),(_vm.poem.image)?_c('v-card-text',{staticClass:"white text--primary"},[_c('v-img',{attrs:{"position":"center","src":'/images/' + _vm.poem.image.src,"aspect-ratio":_vm.poem.image.aspectRatio}})],1):_c('v-card-text',{staticClass:"white text--primary pt-2"},[(_vm.isLoading)?_c('rowlo-circular-progress'):(_vm.showFullText)?_vm._l((_vm.poem.verses),function(verse,v){return _c('rowlo-poem-verse',{key:v,attrs:{"titleId":v,"verse":verse}})}):[_c('p',[_vm._l((_vm.poem.teaserLines.slice(
            0,
            Math.min(3, _vm.poem.teaserLines.length)
          )),function(line,l){return _c('span',{key:l},[_vm._v(" "+_vm._s(line)),_c('br')])}),(_vm.poem.teaserLines.length > 3)?_c('span',{class:[{ clickable: _vm.canToggleShowFullText }],on:{"click":_vm.toggleShowFullText}},[_vm._v("..."),_c('br')]):_vm._e()],2)],(_vm.isWithSignature)?_c('p',{staticClass:"mt-2"},_vm._l((_vm.poem.signature),function(sig,s){return _c('span',{key:s,staticClass:"signature-text",style:(("color: " + (_vm.poem.color) + ";"))},[_vm._v(_vm._s(sig)),_c('br')])}),0):_vm._e(),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"center","justify":"space-between"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"center","justify":"start"}},[(_vm.canToggleShowFullText)?_c('v-btn',{staticClass:"mx-0 mr-2",attrs:{"color":_vm.poem.color,"outlined":""},on:{"click":_vm.toggleShowFullText}},[_vm._v(" "+_vm._s(_vm.$t(_vm.toggleButtonText))+" ")]):_vm._e(),(_vm.showSinglePoemRouteAction)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","icon":"","color":_vm.poem.color,"to":_vm.routes.poem}},on),[_c('v-icon',[_vm._v("$vuetify.icons.view")])],1)]}}],null,false,2741659836)},[_c('span',[_vm._v(_vm._s(_vm.$t('onlyShowPoemTooltip', { poemId: _vm.poem.id })))])]):_vm._e(),(_vm.isWithBackToListAction)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","icon":"","color":_vm.poem.color,"to":_vm.routes.poems}},on),[_c('v-icon',[_vm._v("$vuetify.icons.back")])],1)]}}],null,false,1762170441)},[_c('span',[_vm._v(_vm._s(_vm.$t('backToListTooltip', { poemId: _vm.poem.id })))])]):_vm._e()],1),(_vm.visualizeLoevingerStage)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","icon":"","color":_vm.poem.color,"href":_vm.$t('loevingerUrl'),"target":"_blank"}},on),[_vm._v(" "+_vm._s(_vm.poem.loevingerStage)+" ")])]}}],null,false,2690871226)},[_c('span',[_vm._v(_vm._s(_vm.$t('loevingerUrlText')))])]):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }