<template>
  <v-col cols="12" class="ma-0 pa-0">
    <v-row justify="center" align="center" class="ma-0 pa-0">
      <v-col cols="auto" class="ma-0 pa-0">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'rowlo-circular-progress',
}
</script>
