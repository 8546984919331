<template>
  <v-tooltip
    :id="id + '.tooltip'"
    :top="top"
    :right="right"
    :left="left"
    :bottom="bottom"
    :open-delay="openDelay"
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on }">
      <span :id="id" v-on="on"><slot></slot></span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
import VTooltipPropsMixin from '@/mixins/v-tooltip-props-mixin.js'

export default {
  name: 'tooltipped-span',
  mixins: [VTooltipPropsMixin],
}
</script>
