<template>
  <v-card
    :id="'rowlo-poem-card-' + poem.title.replace(/\s/g, '-').toLowerCase()"
    :color="poem.color"
  >
    <v-card-title
      :class="['title', 'white-title', { clickable: canToggleShowFullText }]"
      @click="toggleShowFullText"
    >
      <tooltipped-span
        v-if="!poem.image"
        :id="'poem-' + poem.id"
        :text="$tc('views', poem.views, { n: poem.views })"
        >#{{ poem.id.toString().padStart(3, '0') }}: {{ poem.title }}
      </tooltipped-span>
      <span v-else>{{ $t(poem.title) }}</span>
    </v-card-title>

    <v-card-text v-if="poem.image" class="white text--primary">
      <v-img
        position="center"
        :src="'/images/' + poem.image.src"
        :aspect-ratio="poem.image.aspectRatio"
      ></v-img>
    </v-card-text>

    <v-card-text v-else class="white text--primary pt-2">
      <rowlo-circular-progress v-if="isLoading"></rowlo-circular-progress>

      <template v-else-if="showFullText">
        <rowlo-poem-verse
          v-for="(verse, v) in poem.verses"
          :key="v"
          :titleId="v"
          :verse="verse"
        ></rowlo-poem-verse>
      </template>

      <template v-else>
        <p>
          <span
            v-for="(line, l) in poem.teaserLines.slice(
              0,
              Math.min(3, poem.teaserLines.length)
            )"
            :key="l"
          >
            {{ line }}<br />
          </span>
          <span
            v-if="poem.teaserLines.length > 3"
            @click="toggleShowFullText"
            :class="[{ clickable: canToggleShowFullText }]"
            >...<br
          /></span>
        </p>
      </template>

      <p v-if="isWithSignature" class="mt-2">
        <span
          class="signature-text"
          :style="`color: ${poem.color};`"
          v-for="(sig, s) in poem.signature"
          :key="s"
          >{{ sig }}<br
        /></span>
      </p>

      <v-row align="center" justify="space-between" class="ma-0 pa-0">
        <v-row align="center" justify="start" class="ma-0 pa-0">
          <v-btn
            v-if="canToggleShowFullText"
            :color="poem.color"
            class="mx-0 mr-2"
            outlined
            @click="toggleShowFullText"
          >
            {{ $t(toggleButtonText) }}
          </v-btn>
          <v-tooltip v-if="showSinglePoemRouteAction" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                icon
                :color="poem.color"
                v-on="on"
                :to="routes.poem"
              >
                <v-icon>$vuetify.icons.view</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('onlyShowPoemTooltip', { poemId: poem.id }) }}</span>
          </v-tooltip>

          <v-tooltip v-if="isWithBackToListAction" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                icon
                :color="poem.color"
                v-on="on"
                :to="routes.poems"
              >
                <v-icon>$vuetify.icons.back</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('backToListTooltip', { poemId: poem.id }) }}</span>
          </v-tooltip>
        </v-row>

        <v-tooltip v-if="visualizeLoevingerStage" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              outlined
              icon
              :color="poem.color"
              v-on="on"
              :href="$t('loevingerUrl')"
              target="_blank"
            >
              {{ poem.loevingerStage }}
            </v-btn>
          </template>
          <span>{{ $t('loevingerUrlText') }}</span>
        </v-tooltip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { eventBus } from '@/main.js'
/**
 * api-mixin.js
 * requires:
 * provides:
 *    data: [isLoading]
 *    methods: [apiGet]
 */
import apiMixin from '@/mixins/api-mixin.js'
import breakpoint from 'vue-md-breakpoint'
import TooltippedSpan from '@/components/tooltipped/tooltipped-span'
import RowloCircularProgress from '@/components/rowlo-circular-progress'
import RowloPoemVerse from '@/components/rowlo-poem-verse'

export default {
  name: 'rowlo-poem-card',
  mixins: [apiMixin, breakpoint],
  props: {
    startWithFullText: {
      type: Boolean,
      required: false,
      default: false,
    },
    withSignature: {
      type: Boolean,
      required: false,
      default: false,
    },
    withToPoemsRoute: {
      type: Boolean,
      required: false,
      default: false,
    },
    poem: Object,
  },
  components: {
    'tooltipped-span': TooltippedSpan,
    'rowlo-circular-progress': RowloCircularProgress,
    'rowlo-poem-verse': RowloPoemVerse,
  },
  data: () => ({
    showFullText: false,
    isLoevingerInRoute: false,
  }),
  computed: {
    routes() {
      let language = eventBus.getLanguageLoevingerCycleQueryForRoute()
      return {
        poems: '/poems/' + language,
        poem: '/poem/' + this.poem.id + '/' + language,
      }
    },
    poemTitleAsId() {
      return this.poem.title.replace(/\s/g, '-').toLowerCase()
    },
    toggleButtonText() {
      return this.showFullText ? 'showLess' : 'showAll'
    },
    showSinglePoemRouteAction() {
      if (this.$route.params.poemId) {
        return false
      }
      return !this.poem.noActions
    },
    isWithSignature() {
      return (
        this.poem.signature &&
        (this.withSignature ||
          !this.showSinglePoemRouteAction ||
          this.showFullText)
      )
    },
    canToggleShowFullText() {
      return (
        this.showSinglePoemRouteAction &&
        this.$breakpoint.smAndUp &&
        !this.poem.noActions
      )
    },
    isWithBackToListAction() {
      return this.withToPoemsRoute
    },
    visualizeLoevingerStage() {
      return this.poem.loevingerStage && eventBus.loevingerInRoute
    },
  },
  methods: {
    toggleShowFullText() {
      if (this.canToggleShowFullText) {
        if (!this.poem.verses) {
          this.fetchPoem()
        }
        this.showFullText = !this.showFullText
      }
    },
    processPoem(poem) {
      eventBus.updatePoem(poem)
    },
    fetchPoem() {
      this.apiGet('poems/poem/' + this.poem.id, this.processPoem)
    },
  },
  watch: {
    startWithFullText: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        this.showFullText = this.startWithFullText
      },
    },
  },
  mounted() {
    this.showFullText = this.startWithFullText
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.signature-text {
  font-family: 'Arizonia', cursive;
  font-size: 26px;
}
.white-title {
  color: #ffffff;
}
.vertical-spacer {
  min-height: 25px;
  min-width: 1px;
}
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
